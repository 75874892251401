import { startTransition } from "react";
import { hydrateRoot } from "react-dom/client";
import { RemixBrowser } from "~/remix";
import { isSentryEnable } from "./helpers/utils";

if (isSentryEnable()) {
  import('./helpers/monitoring.client.tsx').then(({ init }) => init())
}

const hydrate = () => {
  startTransition(() => {
    hydrateRoot(document, <RemixBrowser />);
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  window.setTimeout(hydrate, 1);
}